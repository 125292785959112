<template>
  <div class="px-4 py-4 hover:bg-gray-100 sm:px-6">
    <NuxtLink no-prefetch :to="`/matches/${props.match.match.id}`">
      <div
        class="flex cursor-pointer flex-col justify-between space-x-0 space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0"
        @click="navigateToMatch">
        <div class="min-w-0 space-y-2">
          <p
            class="truncate font-secondary text-base font-medium text-gray-900 hover:underline">
            {{ matchName }}
            <span class="font-mono text-sm font-light text-gray-500"
              >#{{ match.match.id }}</span
            >
          </p>
          <div class="flex items-center text-xs text-gray-500">
            <div>{{ match.court.name }}</div>
            <svg viewBox="0 0 2 2" class="mx-2 h-0.5 w-0.5 fill-current">
              <circle cx="1" cy="1" r="1" />
            </svg>
            <time :datetime="match.match.startedAt" class="font-semibold">
              {{
                new Date(match.match.startedAt).toLocaleString([], {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })
              }}
            </time>
            <svg viewBox="0 0 2 2" class="mx-2 h-0.5 w-0.5 fill-current">
              <circle cx="1" cy="1" r="1" />
            </svg>
            <div
              :class="
                match.match.privacy === 'public'
                  ? 'text-celestial'
                  : 'text-crayola'
              ">
              {{ match.match.privacy === 'public' ? 'Pubblica' : 'Privata' }}
            </div>
          </div>
        </div>
        <div class="flex flex-row items-center justify-end space-x-2">
          <div
            class="text-sm font-semibold"
            :class="{
              'text-gray-600':
                match.match.endedAt ||
                (match.match.scheduledEnd &&
                  new Date() >= new Date(match.match.scheduledEnd)),
              'text-green-600':
                !match.match.endedAt &&
                (!match.match.scheduledEnd ||
                  new Date() < new Date(match.match.scheduledEnd)),
            }">
            <span
              v-if="
                match.match.endedAt ||
                (match.match.scheduledEnd &&
                  new Date() >= new Date(match.match.scheduledEnd))
              ">
              Terminata
              <time
                :datetime="match.match.endedAt || match.match.scheduledEnd"
                class="text-xs text-gray-500">
                {{
                  new Date(
                    match.match.endedAt || match.match.scheduledEnd,
                  ).toLocaleString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                }}
              </time>
            </span>
            <span v-else>
              In corso
              <span
                v-if="match.match.scheduledEnd"
                class="text-xs text-gray-500"
                >({{
                  Math.ceil(
                    (new Date(match.match.scheduledEnd) - new Date()) / 60000,
                  )
                }}
                min rimanenti)</span
              >
            </span>
          </div>
          <div class="flex">
            <dt class="sr-only">Giocatori</dt>
            <dd v-for="player in match.players" :key="player.id">
              <PlayersAvatar
                :title="player.name || undefined"
                :profile-picture="player.image || undefined"
                :img-class="'h-6 w-6 ring-2 ring-white'" />
            </dd>
          </div>
          <ChevronRightIcon
            class="h-8 w-8 flex-shrink-0 text-gray-400"
            aria-hidden="true" />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import type { CompleteMatch } from '~/types/db'

const props = defineProps({
  match: {
    type: Object as PropType<CompleteMatch>,
    required: true,
  },
})

const matchName = computed(() => props.match.match.name || 'Partita di Padel')

const navigateToMatch = async () => {
  await navigateTo(`/matches/${props.match.match.id}`)
}
</script>

<style></style>
